.introduction {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    width: 100%;
    height: 50%;
    padding: 10%;
}
.blur {
    position: fixed;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    margin: 10%;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-size: cover;
    top: -10%;
    left: 0;
    pointer-events: none;
}
.showInfo {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 350px;
}

.image {
    width: 250px;
    height: 350px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.name {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
}
.gender {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
}
.birthday, .country {
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
}