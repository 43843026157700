.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}
.sidebar > * {
    width: 100%;
}
.visible {
    width: 15vw;
    background-color: #594545;
    transition-duration: 0.25s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.invisible {
    width: 5vw;
    background-color: rgba(158, 118, 118, 0.25);
    transition-duration: .5s;
    border-right: #815B5B 1px solid;
}
.brand {
    display: flex;
    margin-top: 2rem;
    height: 25vh;
    min-height: 10rem;
    width: 100%;
    transition-duration: 1s;
}
.visible .brand {
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: row;
    color: rgba(158, 118, 118, 0.25);
}
.invisible .brand {
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    color: white;
    transition-duration: 0s;
}

.logo {
    font-size: 3rem;
    transition-duration: 1s;
}
.visible .logo {
    transform: rotate(0deg);
}
.invisible .logo {
    transition-duration:0.2s;
    transform: rotate(270deg);
}

.logoText {
    font-size: 1.5rem;
}
.visible .logoText {
    transform: rotate(0deg);
    border-bottom: #815B5B 1px solid;
}
.invisible .logoText {
    transform: rotate(90deg);
}

.buttonGroup {
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.buttonGroup > * {
    height: 100%;
    max-height: 5vw;
}

.routeButton {
    background-color: transparent;
    border: transparent;
    text-align: center;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.routeButton:hover:not(.switchOn) {
    background-color: rgba(255, 248, 234, 0.05);
    color: #fff;
}
.switchOn {
    background-color: #815B5B;
    color: #fff;
}
.routeButtonImage {
    font-size: 24px;
    color: white;

}