.gridView {
    width: 100%;
    height: 100%;
    padding: 2vh 5vw;
    margin-top: 2.5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-bottom: #815B5B 1px solid;
    border-top: #815B5B 1px solid;

}
.gridView > span {
    color: white;
    font-size: 3em;
    font-family: 'Courier New', Courier, monospace;
}
.gridContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: space-evenly;
}
.landscape .gridContainer {
    grid-template-columns: repeat(auto-fit, 310px);
}
.item {
    padding: 10%;
    display: flex;
    flex-direction: column;
}
.image {
    width: 200px;
    height: 300px;
    object-fit: cover;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.landscape .image{
    width: 300px;
    height: 200px;
}
.name {
    color: white;
    width: 200px;
}